.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* landing page */
.carousel-root {
  height: 100vh;
  width: 100%;
}

.carousel .slide img {
  object-fit: cover;
  height: 100vh;
}

#h4-desktop {
  display: block;
}

#h4-mobile {
  display: none;
}

#socials {
  flex-direction: column-reverse;
}

#socials-desk {
  display: flex;
  width: 95%;
    flex-direction: row;
    margin-top: 17px;
    justify-content: space-between;
}

#socials-mobile {
  display: none;
}


@media (max-width: 600px) {
  #landing-wrap {
    flex-direction: column;
    overflow: hidden;
  }

  #slide-img {
    height: 46vh;
    object-fit: fill;
  }

  .carousel-root {
    height: 46vh;
  }

  #learnmore-wrap {
    bottom: 69%;
  }

  #learnmore-btn {
    background-color: #AD1328;
    color: white; 
  }

  #form-wrap {
    height: 54vh;
  }

  #h4-desktop {
    display: none;
  }

  #h4-mobile {
    display: block;
    /* font-size: 1.2rem; */
    margin-top: 8px;
    margin-bottom: -8px;
  }

  #form-container {
    height: 54vh;
  }

  #form-email .MuiOutlinedInput-root,
  #form-pass .MuiOutlinedInput-root {
    font-size: 0.875rem; /* Reduce the font size */
    height: 40px; /* Adjust the height */
  }

  #form-btn {
    margin-top: 8px;
    margin-bottom: 0px;
  }

  #socials-desk {
    display: none;
  }

  #socials-mobile {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    width: 100%;
    justify-content: space-around;
  }

  #socials {
    flex-direction: column-reverse;
    margin-top: 4px;
    /* margin-left: -46px; */
  }

  #social-icons {
    gap: 0px;
  }

  #social-icons a{
    padding: 4px;
  }
}

@media (min-width: 600px) {

}